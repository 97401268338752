/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalRemoveTgAccountName.vue?vue&type=template&id=36011d26&scoped=true"
import script from "./ModalRemoveTgAccountName.vue?vue&type=script&lang=js"
export * from "./ModalRemoveTgAccountName.vue?vue&type=script&lang=js"
import style0 from "./ModalRemoveTgAccountName.vue?vue&type=style&index=0&id=36011d26&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36011d26",
  null
  
)

export default component.exports