var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "profile-settings"
  }, [_vm.passwordChanged ? _c('alert', [_vm._v(" Пароль успешно изменен!"), _c('br'), _vm._v(" Также новый пароль был отправлен на ваш email ")]) : _vm._e(), _c('div', {
    staticClass: "part attributes"
  }, [_c('div', {
    staticClass: "attributes-column"
  }, [_c('table', {
    staticClass: "attributes-table"
  }, [_c('tr', {
    staticClass: "attribute"
  }, [_c('td', {
    staticClass: "attribute-name"
  }, [_vm._v("Email:")]), _c('td', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.user.user_email_address) + " ")])]), _vm._m(0), _c('tr', {
    staticClass: "attribute"
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('UiButton', {
    attrs: {
      "variant": "white"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$refs.modalChangePassword.open();
      }
    }
  }, [_vm._v(" Изменить пароль ")]), _c('modal-change-password', {
    ref: "modalChangePassword",
    on: {
      "complete": function ($event) {
        _vm.passwordChanged = true;
      }
    }
  })], 1)])])])]), _vm.childsInfo ? _c('div', {
    staticClass: "part"
  }, [_c('div', {
    staticClass: "attributes-column"
  }, [_c('div', {
    staticClass: "attributes-table"
  }, [_c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v(" Кол-во пользователей в тарифном плане: ")]), _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.countAvailable) + " ")]), _c('div', [_vm.canEnable ? _c('UiButton', {
    attrs: {
      "variant": "white",
      "to": "/"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onInviteChild.apply(null, arguments);
      }
    }
  }, [_vm._v(" Пригласить пользователя ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v("Используется:")]), _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(_vm._s(_vm.countConnected))])])])]), _c('div', {
    staticClass: "users"
  }, [_c('div', {
    staticClass: "user"
  }, [_c('span', {
    staticClass: "user__email"
  }, [_vm._v(_vm._s(_vm.user.user_email_address))]), _c('span', {
    staticClass: "user__main"
  }, [_vm._v(" (основной аккаунт)")])]), _vm._l(_vm.childs, function (item) {
    return _c('div', {
      key: item.link.id,
      staticClass: "user",
      class: {
        disabled: !!item.disabled
      }
    }, [_c('span', {
      staticClass: "user__email"
    }, [_vm._v(_vm._s(item.link.child.user_email_address))]), !item.disabled ? _c('span', {
      staticClass: "user__delete",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onDisableChild(item);
        }
      }
    }, [_c('UiIconTrash', {
      attrs: {
        "width": "20",
        "height": "20"
      }
    })], 1) : _vm._e(), item.disabled && _vm.canEnable ? _c('span', {
      staticClass: "user__undo",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onEnableChild(item);
        }
      }
    }, [_c('UiIconUndo', {
      attrs: {
        "width": "20",
        "height": "20"
      }
    })], 1) : _vm._e()]);
  })], 2), _vm.showNewVariant ? _c('div', {
    staticClass: "attributes-column"
  }, [_c('div', {
    staticClass: "attributes-table"
  }, [_c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" Telegram аккаунт: ")]), _c('div', {
    staticClass: "attribute-value"
  }, [!_vm.tgAccountNameEditing && _vm.tgAccountName ? _c('span', [_vm._v(" " + _vm._s(_vm.tgAccountName) + " ")]) : _c('a', {
    staticClass: "link",
    attrs: {
      "href": "https://t.me/SalesFinderAnalytics_Bot",
      "target": "_blank"
    }
  }, [_vm._v(" Добавить ")])]), _c('div', [_vm.tgAccountName ? _c('button', {
    staticClass: "btn-outline",
    on: {
      "click": _vm.deleteTgAccountName
    }
  }, [_vm._v(" Удалить ")]) : _vm._e()]), _c('div', [_vm.tgAccountNameError ? _c('span', {
    staticClass: "error",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" Неверное имя TG ")]) : _vm._e()])])])]) : _vm._e()]) : _vm._e(), _vm.user && _vm.user.parent ? _c('div', {
    staticClass: "part"
  }, [_c('div', {
    staticClass: "attributes-column"
  }, [_c('div', {
    staticClass: "attributes-table"
  }, [_c('div', {
    staticClass: "attribute"
  }, [_c('div', {
    staticClass: "attribute-name"
  }, [_vm._v("Главный аккаунт")]), _c('div', {
    staticClass: "attribute-value"
  }, [_vm._v(" " + _vm._s(_vm.user.parent.parent.user_email_address) + " ")])])])])]) : _vm._e(), _c('modal-remove-tg-account-name', {
    ref: "modalRemoveTgAccountName"
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', {
    staticClass: "attribute"
  }, [_c('td', {
    staticClass: "attribute-name"
  }, [_vm._v("Пароль:")]), _c('td', {
    staticClass: "attribute-value"
  }, [_vm._v("********")])]);
}];
export { render, staticRenderFns };