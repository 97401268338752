var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Удаление аккаунта Telegram",
      "actions": _vm.actions
    }
  }, [_vm._v(" Вы действительно хотите удалить аккаунт Telegram"), _c('br'), _vm._v(" \"" + _vm._s(this.list.title) + "\"? ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };