/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Undo.vue?vue&type=template&id=7315c715"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports